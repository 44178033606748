
import { Options, Vue } from 'vue-class-component';
import RoleService from '../services/RoleService';
import QuoteService from '@/services/QuoteService';

import LocalStorageService from '@/services/LocalStorageService';
import { mapActions, mapGetters } from 'vuex';

import Pagination from '@/shared/Pagination.vue';
import { defineComponent } from 'vue';
import Location from '@/Interfaces/Location';

interface ComponentData {
  lastPage: number;
  textCount: number;
  searchTimeout: number;
  quoteService: QuoteService;
  roleService: RoleService;
  localStorageService: LocalStorageService;
  quotes: any[];
  loading: boolean;
  locations: Location[];
  locationOptions: any[];
  selectedLocationId: string;
  page: number;
  pageSize: number;
  ArrowAscName: boolean;
  ArrowAscTotal: boolean;
  ArrowAscCreatedBy: boolean;
  searchFieldValue: string;
  paginationFlagShow: boolean;
  lastName: string;
  firstName: string;
  totalCount: number;
  currentPage: number;
  nextPage: number;
  previusePage: number;
  records: number;
  noData: boolean;
  filterFields: any[];
}

export default defineComponent({
  name: 'ListQuote',
  components: {
    Pagination,
  },
  data() {
    const data: ComponentData = {
      lastPage: 0,
      filterFields: [],
      textCount: 0,
      searchTimeout: undefined,
      quoteService: undefined,
      roleService: undefined,
      localStorageService: undefined,
      quotes: [],
      loading: false,
      locations: [],
      locationOptions: [],
      selectedLocationId: undefined,
      page: 1,
      pageSize: 15,
      ArrowAscName: true,
      ArrowAscTotal: true,
      ArrowAscCreatedBy: true,
      searchFieldValue: '',
      paginationFlagShow: true,
      lastName: '',
      firstName: '',
      totalCount: 0,
      currentPage: 0,
      nextPage: 0,
      previusePage: 0,
      records: 0,
      noData: true,
    };
    return data;
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch('getLocationList');
    this.locations = this.$store.getters.allLocationList;
    this.setLocationOptions();
    if (this.selectedLocationId) {
      this.changeLocation();
    }
  },
  created() {
    this.quoteService = new QuoteService();
    this.roleService = new RoleService(new LocalStorageService());
    this.localStorageService = new LocalStorageService();
    this.selectedLocationId = this.localStorageService.getItem(
      'primary_location_id',
    );
  },
  methods: {
    ...mapActions(['getQuotesList', 'setQuotesFilter']),

    goToPage({ page }) {
      this.getAllItems(page);
    },

    setLocationOptions() {
      this.quotes = [];
      this.locationOptions = this.locationOptions.concat(
        this.locations.map((location: any) => {
          return { value: location.id, text: location.name };
        }),
      );
      if (this.roleService.isStaffOrAdmin) {
        const locationOfUsers = this.localStorageService.getItem('locations');
        if (locationOfUsers != null && locationOfUsers != undefined) {
          const _locationOfUsers = JSON.parse(locationOfUsers);
          if (_locationOfUsers.length === 1) {
            this.selectedLocationId =
              _locationOfUsers[0].id ||
              this.localStorageService.getItem('primary_location_id');
            this.changeLocation();
          }
        }
      }
    },

    async changeLocation() {
      this.localStorageService.store(
        'primary_location_id',
        this.selectedLocationId,
      );
      this.loading = true;

      this.quotes = [];
      this.$store.commit('setQuotesFilter', {
        locationId: this.selectedLocationId,
      });
      await this.getAllItems(1);
      this.loading = false;
    },

    async getAllItems(page: any, searchNumber?: number) {
      this.loading = true;
      this.$store.commit('setQuotesFilter', {
        search: this.searchFieldValue,
        page: this.page,
        pageSize: this.pageSize,
      });
      try {
        await this.$store.dispatch('getQuotesList');
        if (!searchNumber || this.textCount == searchNumber) {
          this.loading = false;
          this.quotes = this.$store.getters.allQuotes_List;
          this.records = this.$store.getters.quoteFilter.totalCount;
          this.lastPage = this.$store.getters.quoteFilter.lastPage;
          this.totalCount = Math.ceil(this.records / this.pageSize);
          this.currentPage = page;
          this.nextPage = 0;
          this.previusePage = this.currentPage - 1;
          if (this.currentPage < this.totalCount) {
            this.nextPage = this.currentPage + 1;
          }
          if (this.quotes.length) {
            this.noData = false;
          }
        }
      } catch (error) {
        console.log(error);
        this.noData = true;
      }

      this.loading = false;
    },

    handleSorting(para1: any, para2: any) {
      if (para1 === 'name') {
        this.ArrowAscName = !this.ArrowAscName;
      } else if (para1 === 'total') {
        this.ArrowAscTotal = !this.ArrowAscTotal;
      } else if (para1 === 'createdBy') {
        this.ArrowAscCreatedBy = !this.ArrowAscCreatedBy;
      }
      this.loading = true;

      this.$store.commit('setQuotesFilter', {
        orderBy: para2,
        orderByField: para1,
      });
      this.getAllItems(1);
    },

    handleSearchField(event: any, para: string) {
      this.textCount++;
      const temp = this.textCount;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      if (event.target.value.trim() === '') {
        this.filterFields = this.filterFields.filter(
          (item: any) => item.searchField !== para,
        );
      } else {
        this.filterFields = this.filterFields.filter(
          (item: any) => item.searchField !== para,
        );
        const filterObject = {
          search:
            event.target.value.trim() === ''
              ? this.searchTerm
              : event.target.value,
          searchField: para,
        };
        this.filterFields.push(filterObject);
      }
      this.searchTimeout = setTimeout(() => {
        this.loading = true;
        this.searchFieldValue = event.target.value;
        this.$store.commit('setQuotesFilter', {
          searchField: para,
          orderByField: para,
          firstName:
            this.firstName.trim() !== '' ? this.firstName.trim() : null,
          lastName: this.lastName.trim() !== '' ? this.lastName.trim() : null,
        });
        this.getAllItems(1, temp);
      }, 1000);
    },

    goToQuoteDetails(id: string) {
      this.$store.commit('resetQuotesFilter');
      this.$router.push(
        '/quote/pdf?id=' + id + '&locationId=' + this.selectedLocationId,
      );
    },

    pageEvent({ page }) {
      this.page = page;
      this.$store.commit('setQuotesFilter', { page });
      this.getAllItems(page, this.textCount);
    },

    setPageSize(pageSize: number) {
      this.pageSize = pageSize;
      (this.page = 1),
        this.$store.commit('setQuotesFilter', {
          page: this.page,
          pageSize: this.pageSize,
        });
      this.getAllItems(this.page, this.textCount);
    },
  },
  computed: mapGetters(['allQuotes_List', 'quoteFilter']),
});
